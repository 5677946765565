import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Text, Heading, Box, Button } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "600px" },
    width: "100%",
    px: "24px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "inner-box": {
    margin: "0 auto",
    mt: { base: "32px", md: "32px", lg: "80px" },
    width: "100%",
    height: { base: "100%", md: "100%", lg: "456px" },
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: { base: "flex-start", md: "flex-start", lg: "center" },
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "700px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    mr: { base: "0px", md: "0px", lg: "135px" },
  },
  "heading-one": {
    mt: { base: "24px", md: "24px", lg: "0px" },
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  "heading-two": {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontWeight: "bold",
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    lineHeight: { base: "28px", md: "28px", lg: "40px" },
    color: "#595C5F",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  text: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  image: {
    height: { base: "auto", md: "auto", lg: "313.95px" },
    maxWidth: { base: "100%", md: "100%", lg: "658px" },
  },
  "icon-box": {
    display: { base: "flex", md: "flex", lg: "none" },
    my: "17px",
    width: "100%",
    justifyContent: "center",
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: { base: "100%", md: "100%", lg: "232px" },
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us/")

const SectionHero = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["icon-box"]}>
        <ChevronDownIcon sx={styles.icon} />
      </Flex>
      <Flex sx={styles["left-box"]}>
        <Heading sx={styles["heading-one"]}>
          Which small change will improve care quality & reduce cost the most?
        </Heading>
        <Heading sx={styles["heading-two"]} mt="24px">
          Let's take a closer look...
        </Heading>
        <Text sx={styles.text}>
          With Avant-garde Health, hospitals, ASCs, and physicians finally have
          the level of detail needed to see opportunities for improvement they
          couldn’t see before. Those details include the cost of supplies, OR
          times, and many others – across physicians, departments, hospitals,
          ASCs, and even other providers.
        </Text>
        <Button sx={styles.button} onClick={navigateToContactUs}>
          Connect with Us Today!
        </Button>
      </Flex>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/homepage/hp-hero-a.jpeg"
          alt="Home page hero image"
          placeholder="blurred"
        />
      </Box>
    </Flex>
  </Box>
)

export default SectionHero
