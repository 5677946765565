import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import {
  Flex,
  Text,
  Button,
  Heading,
  Box,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "624px" },
    width: "100%",
    py: { base: "32px", md: "32px", lg: "64px" },
    px: "24px",
  },
  "inner-box": {
    margin: "0 auto",
    mt: "36px",
    flexDirection: { base: "column", md: "column", lg: "row" },
    justifyContent: { base: "flex-start", md: "flex-start", lg: "center" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    width: "100%",
  },
  "left-box": {
    width: { base: "100%", md: "100%", lg: "432px" },
    flexDirection: "column",
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    mr: { base: "0px", md: "0px", lg: "26px" },
  },
  "right-box": {
    width: { base: "100%", md: "100%", lg: "432px" },
    flexDirection: "column",
    alignItems: "center",
  },
  "heading-one": {
    margin: "0 auto",
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#041424",
    textAlign: "center",
    width: { base: "auto", md: "auto", lg: "700px" },
  },

  text: {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  image: {
    textAlign: "center",
    mt: { base: "40px", md: "40px", lg: "0px" },
    width: { base: "247px", md: "247px", lg: "281px" },
    height: { base: "320px", md: "320px", lg: "364px" },
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "228px",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const SectionCaseStudy = () => (
  <Box sx={styles.root}>
    <Heading sx={styles["heading-one"]}>
      One of the nation's leading hospitals is saving $5,900 per case
    </Heading>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["left-box"]}>
        <Text
          sx={{
            mt: "8px",
            textAlign: { base: "center", md: "center", lg: "left" },
            fontSize: "16px",
            lineHeight: "150%",
            letterSpacing: "0.0015em",
            color: "#041424",
            px: { base: "24px", md: "24px", lg: "0px" },
          }}
        >
          Learn how a high performing health system is using advanced analytics
          to expose the true costs of orthopedic care inside the hospital, as
          well as the spending for patients post-discharge. With these insights
          they have achieved:
        </Text>
        <UnorderedList sx={styles.text} mt="24px">
          <ListItem>10% Lower costs for surgical supplies</ListItem>
          <ListItem>44% Reduction in length of stay</ListItem>
          <ListItem>49% Reduction in post-acute care costs</ListItem>
          <ListItem>86% Reduction in 90-day readmissions</ListItem>
        </UnorderedList>
        <Link
          as={GatsbyLink}
          to={"/hershey-medical-center-case-study/"}
          title="Click here to learn how a high performing health system uses our healthcare data analytics platform to reduce costs and improve patient outcomes."
        >
          <Button sx={styles.button}>Read the Case Study</Button>
        </Link>
      </Flex>

      <Flex sx={styles["right-box"]}>
        <Link as={GatsbyLink} to={"/hershey-medical-center-case-study/"}>
          <Box sx={styles.image}>
            <StaticImage
              src="../../images/homepage/hershey.png"
              alt={
                "Image of the cover page of the Hershey Medical Center case study. Click here to learn how Hershey Medical Center uses our healthcare data analytics platform to reduce costs and improve patient outcomes."
              }
              placeholder="blurred"
            />
          </Box>
        </Link>
      </Flex>
    </Flex>
  </Box>
)

export default SectionCaseStudy
