import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Flex, Heading, Text, Link } from "@chakra-ui/react"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "496px" },
    width: "100%",
    px: "24px",
    py: { base: "32px", md: "32px", lg: "0px" },
  },
  blocks: {
    margin: "0 auto",
    height: "100%",
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "center" },
    justifyContent: "space-between",
    maxWidth: { base: "100%", md: "100%", lg: "1440px" },
  },
  "block-title": {
    mt: "40px",
    fontWeight: "bold",
    fontSize: { base: "20px", md: "20px", lg: "32px" },
    lineHeight: { base: "28px", md: "28px", lg: "40px" },
    color: "#000000",
    textAlign: "center",
  },
  "block-text": {
    mt: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: "center",
  },
  link: {
    mt: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#3399FF",
    textDecoration: "underline",
    _hover: {
      color: "#3399FF",
    },
  },
  "quote-box": {
    margin: "0 auto",
    mt: "40px",
    flexDirection: "column",
    alignItems: "center",
    width: { base: "100%", md: "100%", lg: "800px" },
  },
  "quote-title": {
    fontWeight: "bold",
    fontSize: { base: "20px", md: "20px", lg: "24px" },
    lineHeight: { base: "28px", md: "28px", lg: "32px" },
    color: "#041424",
    textAlign: "center",
  },
  "quote-text": {
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
}

const Block = ({ title, Image, text, to, mx, mt }) => (
  <Flex
    sx={{
      flexDirection: "column",
      alignItems: "center",
      width: { base: "311px", md: "311px", lg: "350px" },
      mx,
      mt,
    }}
  >
    <Image />
    <Heading sx={styles["block-title"]}>{title}</Heading>
    <Text sx={styles["block-text"]}>{text}</Text>
    <Link as={GatsbyLink} to={to} sx={styles.link} mt="8px">
      Learn more
    </Link>
  </Flex>
)

const SectionCaremeasurement = () => (
  <Box sx={styles.root}>
    <Flex sx={styles.blocks}>
      <Block
        title="See the details"
        text="Drill down to a level of clinical, financial, and operational detail that you weren’t able to see before."
        to="/caremeasurement/#see-the-details"
        Image={() => (
          <StaticImage
            src="../../images/homepage/see-details.png"
            alt="See the Details"
            placeholder="blurred"
            style={{ width: "160px", height: "160px" }}
          />
        )}
      />
      <Block
        title="Compare the findings"
        text="Benchmark the data across your department, your hospital, your health system, and others like yours."
        to="/caremeasurement/#compare-the-findings"
        mx="24px"
        mt={{ base: "48px", md: "48px", lg: "0px" }}
        Image={() => (
          <StaticImage
            src="../../images/homepage/compare.png"
            alt="Compare the findings"
            placeholder="blurred"
            style={{ width: "160px", height: "160px" }}
          />
        )}
      />
      <Block
        title="Develop the playbooks"
        text="Get help turning all these insights into specific action steps from a team that’s done this many times before."
        to="/caremeasurement/#develop-the-playbooks"
        mt={{ base: "48px", md: "48px", lg: "0px" }}
        Image={() => (
          <StaticImage
            src="../../images/homepage/develop.png"
            alt="Develop the playbooks"
            placeholder="blurred"
            style={{ width: "160px", height: "160px" }}
          />
        )}
      />
    </Flex>
  </Box>
)

export default SectionCaremeasurement
